import React from 'react';
import { graphql } from 'gatsby';

import Page from '../components/Page';

const page = ({ data, pageContext, path, location }) => {
  return <Page {...data.sanityPage} home={pageContext.home} latest={data.latest} />;
};

export const PageQuery = graphql`
  query pageQuery($id: String!) {
    sanityPage(id: { eq: $id }) {
      title
      content: _rawContent(resolveReferences: { maxDepth: 15 })
      mainImage {
        alt
        asset {
          _id
          metadata {
            lqip
            dimensions {
              aspectRatio
            }
          }
        }
        crop {
          top
          left
          bottom
          right
        }
        hotspot {
          x
          y
          height
          width
        }
      }
      seoOptions {
        metaDescription
        title
      }
    }
    latest: sanityPost {
      id
      title
      slug {
        current
      }
      excerpt
    }
  }
`;

export default page;
