import React from 'react';
import styled from 'styled-components';

import Content from './Content';
import { media } from './theme';
import useSiteContext from './SiteContext';
import Img from './Img';
import ArchivePost from './ArchivePost';

const Page = ({ title, content, mainImage, home, seoOptions, latest }) => {
  const aspect = mainImage?.asset.metadata.dimensions.aspectRatio;
  // a = w / h
  // ah = w
  // h = w / a
  const { width, pageContentSize } = useSiteContext();

  const height = Math.round(width > 768 ? (pageContentSize.width - 80) / aspect : pageContentSize.width / aspect);

  return (
    <StyledPage className="page" home={home} imgHeight={height}>
      {!home && <h1 className="page__title">{title}</h1>}
      {/* {mainImage && <SanityImage {...mainImage} className="page__image" alt={mainImage.alt} width={1024} />} */}
      <Img className="page__image" {...mainImage} image={mainImage} />
      <Content className="page__content">{content}</Content>
      {home && latest && (
        <div style={{ padding: '0 3rem' }}>
          <h2>{`What’s Happening at CASA of Hinds County:`}</h2>
          <ArchivePost {...latest} />
        </div>
      )}
    </StyledPage>
  );
};

const StyledPage = styled.div`
  height: 100%;
  .page {
    &__title {
      background: ${({ theme }) => theme.light};
      color: ${({ theme }) => theme.blue};
      text-transform: uppercase;
      text-align: center;
      padding: 1rem 0;
      margin: 0;
    }
    &__image {
      margin-bottom: 2rem;
      width: 100%;
    }
    &__content {
      padding: 1rem 3rem;
      text-align: ${({ home }) => (home ? 'center' : 'inherit')};
      ${media.break`
        text-align: initial; 
      `}
    }
  }
  ${media.break`
    background: white;
    display: grid;
    grid-template-rows: repeat(3, auto);
    width: ${({ theme }) => theme.sizes.large}px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    .page {
      &__title {
        grid-row: 2 / 3;
        background: none;
        text-align: left;
        padding: 1rem 3rem;
        color: ${({ theme }) => theme.red};
        &::after {
          content: '';
          width: 100%;
          height: 1px;
          display: block;
          background: ${({ theme }) => theme.red};
        }
      }
    }
  `}
`;

export default Page;
