import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const ArchivePost = ({ slug, title, excerpt }) => {
  return (
    <StyledPost className="post">
      <Link className="post__link" href={`/post/${slug.current}`}>
        <h2 className="post__title">{title}</h2>
        <p className="post__excerpt">{excerpt}</p>
      </Link>
    </StyledPost>
  );
};

const StyledPost = styled.article`
  border-bottom: 1px solid ${({ theme }) => theme.black};
  margin-bottom: 1.5rem;
  &:last-child {
    border-bottom: 0;
  }
  .post {
    &__link {
      &:hover {
        text-decoration: none;
        .post__title {
          text-decoration: underline;
        }
      }
    }
    &__title {
      margin: 0;
    }
    &__excerpt {
      color: ${({ theme }) => theme.black};
    }
  }
`;

export default ArchivePost;
